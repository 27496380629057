import { useEffect, useContext } from "react"
import { useMe } from '../../hooks/useMe'
import UiStoreContext from "../../contexts/uiStoreContext"
import { navigate } from "gatsby"
import useInterval from '../../hooks/useInterval'

const Verify = () => {

	const { me, refreshMe } = useMe()
	const uiStore = useContext(UiStoreContext)

	useInterval( () => {
		refreshMe()
	}, 1000)

	useEffect(() => {
		if (me && me.paymentSubscriptionId) {
			uiStore.resetPlanFlow()
			navigate('/thankyou')
		}
	}, [me])

	return null 

}

export default Verify
